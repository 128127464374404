import React from 'react';

import Food from 'components/Menu/Food';
import Jumbotron from 'components/misc/Jumbotron';
import TabBar from 'components/misc/TabBar';

const tabBarMenus = [
  { name: '베이커리', link: '/menu/bakery/' },
  { name: '음료', link: '/menu/beverage/' },
  { name: '디저트', link: '/menu/dessert/' },
  { name: '푸드', link: '/menu/food/' },
  { name: '상품', link: '/menu/retail/' },
];

const FoodPage = () => (
  <div>
    <Jumbotron
      engTitle="Food"
      korTitle="푸드"
      image={require('../../assets/images/banner/menu-food.jpg')}
      content={
        <span>
          아티제는 여자를 행복하게 하는 홈파티 컨셉의 테이블을 제공합니다.
          <br />
          정성을 담은 홈메이드 스타일의 요리로
          <br />
          아티제에서 특별한 일상을 경험해 보실 수 있습니다.
          <br />
          <br />
        </span>
      }
    />
    <TabBar index={3} data={tabBarMenus} />
    <Food />
  </div>
);

export default FoodPage;
