import React from 'react';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames/bind';
import Swiper from 'react-id-swiper';
import ContainerDimensions from 'react-container-dimensions';

import styles from './index.module.scss';
import MenuSetLeft from '../common/MenuSetLeft';
import MenuSetRight from '../common/MenuSetRight';
import MenuItemDesc from '../common/MenuItemDesc';
import Divider from '../common/Divider';
import SingleKitchen from './SingleKitchen';
import menus from '../../../datas/salemenu/kitchen.js';
import { params4, params5 } from '../common/swiperParam';

const cx = classNames.bind(styles);
const { sandwichs, kitchens } = menus;

const Food = () => (
  <ScreenClassRender
    render={screenClass => {
      const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

      return (
        <div>
          {/* 샌드위치 */}
          <MenuSetLeft
            engTitle="Sandwich"
            korTitle="샌드위치"
            image={require('../../../assets/images/menu/food/sandwich/big.png')}
            content={
              <span>
                간편 식사도 품격있게,
                <br />
                다양한 맛의 샌드위치
              </span>
            }>
            <div className={cx(['illust', 'clip1', { mobile: isMobile }])} />
          </MenuSetLeft>
          <div className={cx('swiper')}>
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <ContainerDimensions>
                    {({ width }) => {
                      return (
                        <Swiper {...params5}>
                          {sandwichs.map((sandwich, i) => {
                            return (
                              <div key={i} className={cx('item')}>
                                <MenuItemDesc item={sandwich} />
                              </div>
                            );
                          })}
                        </Swiper>
                      );
                    }}
                  </ContainerDimensions>
                </Col>
              </Row>
            </Container>
          </div>

          <Divider />

          {/* 키친 */}
          <MenuSetRight
            engTitle="Kitchen"
            korTitle="키친"
            image={require('../../../assets/images/menu/food/kitchen/big.png')}
            content={
              <span>
                From the farm to your table
                <br />
                신선하고 건강한 제철 재료로,
                <br />
                정성 들여 만든 홈메이드 스타일의 아티제 키친
              </span>
            }>
            <div className={cx(['illust', 'clip2', { mobile: isMobile }])} />
          </MenuSetRight>
          <div className={cx('storeSection')}>
            <Container className={cx('someStore')}>
              <Row>
                <Col xs={12} md={6} lg={5} offset={{ lg: 1 }} className={cx('column')}>
                  <SingleKitchen
                    image={require('../../../assets/images/menu/food/kitchen/store-tower_palace.png')}
                    engName="tower palace"
                    korName="타워팰리스점"
                  />
                </Col>
                <Col xs={12} md={6} lg={5} className={cx('column')}>
                  <SingleKitchen
                    image={require('../../../assets/images/menu/food/kitchen/store-seocho_yeok.png')}
                    engName="seocho yeok"
                    korName="서초역점"
                  />
                </Col>
              </Row>
            </Container>
            <Container className={cx('manyStore')}>
              <Row>
                <Col lg={2.5} offset={{ lg: 1 }}>
                  <div className={cx('engStoreName')}>from the farm to your table</div>
                  <div className={cx('korStoreName')}>서초점, 잠원파스텔점</div>
                </Col>
                <Col lg={7.5}>
                  <div className={cx('storeDesc')}>
                    다양한 재료가 풍부하게 들어간 진한 풍미의 아티제 브레드볼 수프. 제철재료와 홈메이드 소스를 활용한
                    샐러드. 다양한 재료로 깊은 풍미를 느낄 수 있는 파스타와 정성껏 준비한 리조또. 엄선한 최상의 재료와
                    홈메이드 스타일로 차려낸 아티제만의 다양한 키친 메뉴를 만나보세요.
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className={cx('swiper')}>
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <ContainerDimensions>
                    {({ width }) => {
                      return (
                        <Swiper {...params5}>
                          {kitchens.map((kitchen, i) => {
                            return (
                              <div key={i} className={cx('item')}>
                                <MenuItemDesc item={kitchen} />
                              </div>
                            );
                          })}
                        </Swiper>
                      );
                    }}
                  </ContainerDimensions>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }}
  />
);

export default Food;
