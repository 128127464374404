import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { ScreenClassRender } from 'react-grid-system';

import styles from './SingleKitchen.module.scss';
import ScaleImageCell from '../../misc/ScaleImageCell';

const cx = classNames.bind(styles);

const SingleKitchen = ({ image, engName, korName }) => (
  <ScreenClassRender render={(screenClass) => {
    const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

    return (
      <div className={cx({ mobile: isMobile })}>
        <ScaleImageCell image={image} ratio={1 / 1.75} />
        <div className={cx('engStoreName')}>{engName}</div>
        <div className={cx('korStoreName')}>{korName}</div>
      </div>
    );
  }} />
);

SingleKitchen.propTypes = {
  image: PropTypes.string.isRequired,
  engName: PropTypes.string.isRequired,
  korName: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default SingleKitchen;